export function YoutubeIframe({
  src,
  width,
  height,
  title,
}: {
  src: string
  width: number
  height: number
  title?: string
}) {
  return (
    <iframe
      src={src}
      width={width}
      height={height}
      title={title ?? ''}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowFullScreen
    ></iframe>
  )
}

import { Dialog, DialogContent } from '@mui/material'
import Image from 'next/image'
import { useSetRecoilState } from 'recoil'
import { loaderStatus } from '../../recoil/atoms'
import styles from './somethingWrong.module.scss'

type Props = {
  openDialog: boolean
  handleClose: () => void
  somethingWrong: boolean
}

export default function SomethingWrong({ openDialog, handleClose }: Props) {
  const setLoading = useSetRecoilState(loaderStatus)

  const localClose = () => {
    setLoading(false)
    handleClose()
  }

  return (
    <Dialog
      open={openDialog}
      onClose={localClose}
      PaperProps={{
        style: {
          borderRadius: '18px',
        },
      }}
      maxWidth="sm"
    >
      <DialogContent className={styles.border}>
        <div className={styles.modal_box}>
          <div className={styles.modal_header}>
            <div
              className={`${styles.modal_title} ${styles.gradientPurpleText}`}
            >
              Something went wrong
            </div>
            <Image
              src="/assets/blackCross.svg"
              alt="logo"
              width={15}
              height={15}
              className={styles.modal_close}
              onClick={handleClose}
            />
          </div>
          <div className={styles.modal_container_column}>
            <div className={styles.modal_text}>
              During the search process something went wrong, Please try again
            </div>
            <button onClick={handleClose} className={styles.modal_button}>
              try again
            </button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

import { DialogContent } from '@mui/material'
import { useSession } from 'next-auth/react'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import {
  COMPARE_BASIC_PREMIUM_PERKS,
  COMPARE_FREE_PAID_PERKS,
  COMPARE_STARTER_BASIC_PERKS,
  FREESUB_PERKS,
  LEFT_OVER_PREMIUM,
} from '../../data/subPerks'
import { loaderStatus, profileState } from '../../recoil/atoms'
import { SUBSCRIPTION_TYPES } from '../../utils/consts'
import { getPriceData } from '../../services/stripeCallsHelper'
import DialogBase from '../DialogBase/DialogBase'
import styles from './limitReached.module.scss'

type Props = {
  openDialog: boolean
  handleClose: () => void
}

export default function LimitReached({ openDialog, handleClose }: Props) {
  const router = useRouter()
  const limitReason = router.query.limit
  const profile = useRecoilValue(profileState)
  const setLoading = useSetRecoilState(loaderStatus)
  const { status } = useSession()

  const localClose = () => {
    setLoading(false)
    handleClose()
  }

  const [prices, setPrices] = useState<any>({
    [SUBSCRIPTION_TYPES.FREE.id]: {
      annual: null,
      annual_id: null,
      monthly: null,
      monthly_id: null,
    },
    [SUBSCRIPTION_TYPES.BASIC.id]: {
      annual: null,
      annual_id: null,
      monthly: null,
      monthly_id: null,
    },
    [SUBSCRIPTION_TYPES.PREMIUM.id]: {
      annual: null,
      annual_id: null,
      monthly: null,
      monthly_id: null,
    },
  })

  useEffect(() => {
    const getPrice = async () => {
      const priceData = await getPriceData()
      setPrices(priceData)
    }

    getPrice().catch(console.error)
  }, [])

  const limitText = () => {
    const date = new Date()
    const firstOfNextMonth = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      1
    )
    if (profile?.subscriptionData?.activePlan === SUBSCRIPTION_TYPES.BASIC.id) {
      if (limitReason === 'domain') {
        return (
          'You’ve hit your limit on number of different domains you can analyze. You can analyze up to 3 domains on the Basic plan. Upgrade to Premium ($' +
          prices[SUBSCRIPTION_TYPES.PREMIUM.id].monthly +
          '/month) and get the following benefits:'
        )
      }
      if (limitReason === 'topic') {
        return (
          'You’ve hit your limit on number of different topics you can analyze. You can analyze up to 10 topics on the Basic plan. Upgrade to Premium ($' +
          prices[SUBSCRIPTION_TYPES.PREMIUM.id].monthly +
          '/month) and get the following benefits:'
        )
      }
    }

    if (
      profile?.subscriptionData?.activePlan === SUBSCRIPTION_TYPES.STARTER.id
    ) {
      if (limitReason === 'domain') {
        return (
          'You’ve hit your limit on number of different domains you can analyze. You can analyze one domain on the Starter plan. Upgrade to Basic ($' +
          prices[SUBSCRIPTION_TYPES.BASIC.id].monthly +
          '/month) and get the following benefits:'
        )
      }
      if (limitReason === 'topic') {
        return (
          'You’ve hit your limit on number of different topics you can analyze. You can analyze 3 topic on the Starter plan. Upgrade to Basic ($' +
          prices[SUBSCRIPTION_TYPES.BASIC.id].monthly +
          '/month) and get the following benefits:'
        )
      }
    }

    if (profile?.subscriptionData?.activePlan === SUBSCRIPTION_TYPES.FREE.id) {
      if (limitReason === 'domain') {
        return (
          'You’ve hit your limit on number of different domains you can analyze. You can analyze one domain on the Free plan. Upgrade to Basic ($' +
          prices[SUBSCRIPTION_TYPES.BASIC.id].monthly +
          '/month) and get the following benefits:'
        )
      }
      if (limitReason === 'topic') {
        return (
          'You’ve hit your limit on number of different topics you can analyze. You can analyze 1 topic on the Free plan. Upgrade to Basic ($' +
          prices[SUBSCRIPTION_TYPES.BASIC.id].monthly +
          '/month) and get the following benefits:'
        )
      }
    }

    return `You’ve reached the monthly analysis report limit on your Premium plan. You can start searching for keywords again on ${firstOfNextMonth.toLocaleDateString(
      'en-EN',
      { dateStyle: 'medium' }
    )}.`
  }

  return (
    <DialogBase openDialog={openDialog} handleClose={handleClose}>
      <DialogContent className={styles.modal_box}>
        <header className={styles.modal_header_container}>
          <div className={styles.modal_logo_container}>
            <Link href="/" passHref>
              <a className={styles.modal_logo}>
                <Image
                  src="/assets/logo.svg"
                  alt="logo"
                  width={50}
                  height={50}
                />
              </a>
            </Link>
            <Link href="/">
              <a className={styles.modal_logo_name}>
                Topic<span>Ranker</span>
              </a>
            </Link>
          </div>
          <div onClick={localClose} className={styles.modal_close}>
            <Image
              src="/assets/x-circle.svg"
              alt="logo"
              width={60}
              height={60}
            />
          </div>
        </header>
        <div className={styles.modal_main_container}>
          <div className={styles.modal_title}>Yowzer!</div>
          <span className={styles.modal_text}>
            {status === 'authenticated'
              ? limitText()
              : "Looks like you don't have an account yet, you'll need an account if you want to create another topic analysis report. No worries, it's free!  Here is what you get with a free account:"}
          </span>
          {status === 'authenticated' &&
            profile?.subscriptionData?.activePlan ===
              SUBSCRIPTION_TYPES.PREMIUM.id && (
              <>
                <br />
                <span className={styles.modal_text}>
                  Also on your Premium plan:
                </span>
              </>
            )}
          <div className={styles.list}>
            {status === 'authenticated'
              ? profile?.subscriptionData?.activePlan ===
                SUBSCRIPTION_TYPES.PREMIUM.id
                ? LEFT_OVER_PREMIUM.map(({ desc }) => {
                    return (
                      <div className={styles.ul} key={desc}>
                        <span
                          className={`${styles.dot} ${styles.gradientPurple}`}
                        ></span>
                        <span className={styles.ul_text}>{desc}</span>
                      </div>
                    )
                  })
                : profile?.subscriptionData?.activePlan ===
                  SUBSCRIPTION_TYPES.BASIC.id
                ? COMPARE_BASIC_PREMIUM_PERKS.map(({ desc }) => (
                    <div className={styles.ul} key={desc}>
                      <span
                        className={`${styles.dot} ${styles.gradientYellow}`}
                      ></span>
                      <span className={styles.ul_text}>{desc}</span>
                    </div>
                  ))
                : profile?.subscriptionData?.activePlan ===
                  SUBSCRIPTION_TYPES.STARTER.id
                ? COMPARE_STARTER_BASIC_PERKS.map(({ desc }) => (
                    <div className={styles.ul} key={desc}>
                      <span
                        className={`${styles.dot} ${styles.gradientYellow}`}
                      ></span>
                      <span className={styles.ul_text}>{desc}</span>
                    </div>
                  ))
                : COMPARE_FREE_PAID_PERKS.map(({ desc }) => (
                    <div className={styles.ul} key={desc}>
                      <span
                        className={`${styles.dot} ${styles.gradientYellow}`}
                      ></span>
                      <span className={styles.ul_text}>{desc}</span>
                    </div>
                  ))
              : FREESUB_PERKS.map(
                  ({ desc }, i) =>
                    i !== 3 && (
                      <div className={styles.ul} key={desc}>
                        <span
                          className={`${styles.dot} ${styles.gradientYellow}`}
                        ></span>
                        <span className={styles.ul_text}>{desc}</span>
                      </div>
                    )
                )}
          </div>

          <button
            onClick={() => {
              if (status === 'authenticated') {
                if (
                  profile?.subscriptionData?.activePlan ===
                  SUBSCRIPTION_TYPES.PREMIUM.id
                ) {
                  handleClose()
                  return
                }
                router.push(`/settings/subscription`)
              } else {
                router.push('/signup')
              }
            }}
            className={
              profile?.subscriptionData?.activePlan ===
              SUBSCRIPTION_TYPES.BASIC.id
                ? `${styles.upgrade_btn} ${styles.gradientPurple}`
                : styles.upgrade_btn
            }
          >
            {status === 'authenticated'
              ? profile?.subscriptionData?.activePlan ===
                SUBSCRIPTION_TYPES.BASIC.id
                ? 'Upgrade to Premium ($' +
                  prices[SUBSCRIPTION_TYPES.PREMIUM.id].monthly +
                  '/mo)'
                : profile?.subscriptionData?.activePlan ===
                  SUBSCRIPTION_TYPES.PREMIUM.id
                ? 'Okay'
                : 'Upgrade to Basic ($' +
                  prices[SUBSCRIPTION_TYPES.BASIC.id].monthly +
                  '/mo)'
              : 'Sign up free'}
          </button>
        </div>
      </DialogContent>
    </DialogBase>
  )
}

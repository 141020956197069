import { Dialog, Backdrop, Fade, DialogContent } from '@mui/material'
import Link from 'next/link'
import Image from 'next/image'
import { ReactNode, useEffect, useState } from 'react'
import styles from './generatingModal.module.scss'
import { useRouter } from 'next/router'
import { useSetRecoilState } from 'recoil'
import { loaderStatus } from '../../recoil/atoms'
import { COMPARE_FREE_PAID_PERKS } from '../../data/subPerks'
import { SUBSCRIPTION_TYPES } from '../../utils/consts'
import { getPriceData } from '../../services/stripeCallsHelper'
import { YoutubeIframe } from '../YoutubeIframe'

type Props = {
  openDialog: boolean
  handleClose: () => void
}

export default function GeneratingModal({ openDialog, handleClose }: Props) {
  const router = useRouter()
  const setLoading = useSetRecoilState(loaderStatus)

  const localClose = () => {
    setLoading(false)
    handleClose()
  }

  const [prices, setPrices] = useState<any>({
    [SUBSCRIPTION_TYPES.FREE.id]: {
      annual: null,
      annual_id: null,
      monthly: null,
      monthly_id: null,
    },
    [SUBSCRIPTION_TYPES.BASIC.id]: {
      annual: null,
      annual_id: null,
      monthly: null,
      monthly_id: null,
    },
    [SUBSCRIPTION_TYPES.PREMIUM.id]: {
      annual: null,
      annual_id: null,
      monthly: null,
      monthly_id: null,
    },
  })

  useEffect(() => {
    const getPrice = async () => {
      const priceData = await getPriceData()
      setPrices(priceData)
    }

    getPrice().catch(console.error)
  }, [])
  return (
    <Dialog
      open={openDialog}
      onClose={localClose}
      scroll="body"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth
      maxWidth="md"
      classes={{ root: styles.root }}
      PaperProps={{
        style: {
          borderRadius: '15px',
        },
      }}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 700,
      }}
    >
      <Fade in={openDialog} timeout={500}>
        <DialogContent className={styles.modal_box}>
          <header className={styles.modal_header_container}>
            <div className={styles.modal_logo_container}>
              <Link href="/" passHref>
                <a className={styles.modal_logo}>
                  <Image
                    src="/assets/logo.svg"
                    alt="logo"
                    width={50}
                    height={50}
                  />
                </a>
              </Link>
              <Link href="/">
                <a className={styles.modal_logo_name}>
                  Topic<span>Ranker</span>
                </a>
              </Link>
            </div>
            <div onClick={localClose} className={styles.modal_close}>
              <Image
                src="/assets/x-circle.svg"
                alt="logo"
                width={60}
                height={60}
              />
            </div>
          </header>
          <div className={styles.modal_main_container}>
            <div className={styles.modal_title}>
              Hang tight, we’re generating your keyword suggestions.
            </div>
            <span className={styles.modal_text}>
              You are on a free trial account, it will take{' '}
              <strong>60 minutes </strong>to generate the report, you’ll be able
              to view 1 keyword per report and run 1 report per month.
            </span>
            <span className={styles.modal_video_text}>
              Scroll down to skip the wait and change these limits.
            </span>
            <div className={styles.modal_generate_container}>
              <YoutubeIframe
                width={610}
                height={330}
                title="TopicRanker.com Walkthrough"
                src="https://www.youtube.com/embed/yLlqWY6Go6c"
              />
            </div>
          </div>
          <div className={styles.modal_main_container}>
            <span className={styles.modal_upgrade_text}>
              Don&apos;t want to wait? Upgrade to our Basic Plan
              {prices[SUBSCRIPTION_TYPES.BASIC.id].monthly !== null &&
                ' ($' +
                  `${prices[SUBSCRIPTION_TYPES.BASIC.id].monthly}` +
                  '/mo) '}
              to skip the queue and see your report in 15 minutes.
            </span>

            <button
              onClick={() => {
                router.push(`/settings/subscription`)
              }}
              className={`${styles.upgrade_btn} ${styles.gradientYellow}`}
            >
              Upgrade to Basic Plan
              {prices[SUBSCRIPTION_TYPES.BASIC.id].monthly !== null &&
                ' ($' +
                  `${prices[SUBSCRIPTION_TYPES.BASIC.id].monthly}` +
                  '/mo) '}
            </button>

            <div className={styles.list}>
              <span className={styles.add_perks}>
                Additional benefits you will get with a paid subscription:
              </span>
              {COMPARE_FREE_PAID_PERKS.map(({ desc }) => (
                <div className={styles.ul} key={desc}>
                  <span
                    className={`${styles.dot} ${styles.gradientYellow}`}
                  ></span>
                  <span className={styles.ul_text}>{desc}</span>
                </div>
              ))}
            </div>
          </div>
        </DialogContent>
      </Fade>
    </Dialog>
  )
}
